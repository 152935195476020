:root{
  
  --border-color: #adadad;
  --border-color-light: #E2E2E2;

  --ligh-yellow-color: #FE9B1D;
  /* --darker-yellow-color: #ffb048; */

  --highlight-red: red;

  --bg-color-light:#1D5A90;
  --bg-color-mid:#1B548A;
  --bg-color-dark:#124375;


  --font-color-main: #073D60;
  --font-color-light:#DFE5EB;
  /* --font-color-mid:; */
  --font-color-dark: #3a464e;


  --space-thin: 2rem;
  --space-small: 4rem;
  --space-wide: 6rem;


  --font-tiny: .5rem;
  --font-small: .7rem;
  --font-medium: 1rem;
  --font-large: 1.5rem;
  --font-xlarge: 2rem;
  --font-xxlarge: 3rem;
  
}


body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.highlight-red{color: var(--highlight-red);}

.bg-color-yellow{background-color: var(--ligh-yellow-color);}


.bg-color-light{background-color: var(--bg-color-light);}
.bg-color-mid{background-color: var(--bg-color-mid);}
.bg-color-dark{background-color: var(--bg-color-dark);}


.font-color-main{color: var(--font-color-main);}

.font-color-light{color: var(--font-color-light);}
.font-color-mid{color: var(--font-color-mid);}
.font-color-dark{color: var(--font-color-dark);}
.font-color-black{color: black;}


/* FONT SIZINGS */
.font-tiny{font-size: var(--font-tiny);}
.font-small{font-size: var(--font-small);}
.font-medium{font-size: var(--font-medium);}
.font-large{font-size: var(--font-large);}
.font-xlarge{font-size: var(--font-xlarge);}
.font-xxlarge{font-size: var(--font-xxlarge);}
/* FONT SIZINGS */


.weight-300{font-weight: 300;}
.weight-400{font-weight: 400;}
.weight-500{font-weight: 500;}
.weight-600{font-weight: 600;}
.weight-700{font-weight: 700;}
.weight-800{font-weight: 800;}
.weight-900{font-weight: 900;}



/* FLEX STYLINGS */
.flex{ display: flex;}
.flex-column{flex-direction: column;}
.flex-align-center{align-items: center;}
.flex-justify-center{justify-content: center;}
/* FLEX STYLINGS */


/* GRID STYLINGS */
.grid{display: grid;}
.grid-3-columns{grid-template-columns: auto auto auto;}
/* FLEX STYLINGS */


.YellowBracket{
  background-color: var(--ligh-yellow-color);
  padding: 2rem var(--space-wide);
}

.bottom-m-3rem{margin: 0 0 3rem;}

/* PADDINGS */
.padding-2-2{padding: var(--space-thin)}
.padding-0-2{padding: 0 var(--space-thin)}
.padding-2-0{padding: var(--space-thin) 0}

.padding-2-4{padding: var(--space-thin) var(--space-small);}
.padding-0-4{padding: 0 var(--space-small);}

.padding-2-6{padding: var(--space-thin) var(--space-wide);}
.padding-0-6{padding: 0 var(--space-wide);}


.uppervase{text-transform: uppercase;}


.sections-content-paddings{
  padding: 2rem var(--space-small);
}

.padding-right{
  padding-right: var(--space-small);
}

.side-padding{
  padding: 0 var(--space-wide);
}

.side-top-bottom-padding{
  padding: 3rem var(--space-wide);
}

.inner-padding{
  padding: 0.5rem 1.5rem;
}

/* widths */

.half-display-width{
  width: 50vw;
}


.hide-on-desktop{display: none;}



a{
  text-decoration: none;
  color: var(--font-color-light);
}

header{
  background-color: transparent;
  padding: 0 var(--space-wide);
  position: -webkit-sticky;
  position: sticky;
  top: -5rem;
  z-index: 5;
} 

.header-contact-info{
  padding: 1.5rem 0;
  justify-content: space-between;
}

.header-contact-info-address{
  color: var(--font-color-light);
}


.svg-inline--fa{
  color: #4da9fa;
  font-size: 1.5rem;
  margin-right: 1rem;
}

nav{
  background-color: var(--bg-color-light);
  align-items: center;
  /* margin: 0 6rem; */
  justify-content: space-between;
  
}

.logo-div{
  background-color: aliceblue;
  width: 15rem;
  height: 4.5rem;
  clip-path: polygon(0 0, 100% 0%, 82% 100%, 0% 100%);
  
}

.store-name{
  font-weight: 700;
  color: #5D4225;
}

.logo{
  width: 4rem;
}

.nav-div{
  padding-right: 3rem;
}

nav > .nav-div> a{
  transition: .3s;
  margin: 0 1rem;
}

nav > .nav-div> a:hover{
  color: var(--ligh-yellow-color);
}

.active{
  color: var(--ligh-yellow-color);
}


.selected{
  color: #B37F4C;
}

.test{
  width: 100%;
  height: 200vh;
}

.hero{
  padding: 5rem var(--space-wide);
  margin-top: -9rem;
  height: 30rem;
  /* height: 75vh; */
  position: relative;
  /* background-color: #4da9fa; */
}

.man-img{
  position: absolute;
  z-index: 1;
  right: 7rem;
  top: 7rem;
  height: 40rem;
}

.welcome-msg{
  margin-top: 8rem;
  width: 70%;
}

.looking-for{
  font-size: var(--font-large);
  color:var(--font-color-light);
}

.call-us-now{
  transition: .2s;
  color: var(--ligh-yellow-color);
  border: 1px solid var(--ligh-yellow-color);
  padding: .8rem 3rem;
}

.call-us-now:hover{
  background-color: var(--ligh-yellow-color);
  color: white;
}

.no-risk{
  font-size: 1.8rem;
  border-right: 2px solid #B37F4C;
}

.money-back{
  width: 9rem;
  line-height: .7rem;
}

.ThreeLoadingScreens{
  margin-left: 4rem;
}

.LoadingScreen{
  
  transition: .3s;
  /* border-bottom: 1px solid var(--border-color); */
  margin-bottom: 2rem;
}

.LoadingScreen-img{
  background-color: #cde7ff;
  width: 100%;
  height: 11.38rem;
  transition: 'transform .5s'
}

.LoadingScreen-group{
  opacity: .7;
  height: 1rem;
}

.LoadingScreen-Header{
  height: 1.5rem;
  width: 80%;
}

.LoadingScreen-skeleton{
  animation: LoadingScreen-animation 1s linear infinite alternate;

}

@keyframes LoadingScreen-animation{
  0%{
    background-color: #cbe5fd;
  }
  100%{
    background-color: #f1f8ff;
  }
}


.LoadingScreen-link{
  width: 8rem;
}


.ServiceCard{
  background-color: white;
  transition: .3s;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 2rem;
}

.chage-color-on-hover{
  transition: .2s;
}

.ServiceCard:hover .chage-color-on-hover{
  color: rgb(77, 92, 102);
}

.img-frame{
  aspect-ratio: 4 / 2;
  width: 100%;
  height: 11.38rem;
  overflow: hidden;
}

.black{
  color: black;
}
.img-frame:hover .ServiceCard-img{
  transform: scale(1.05); 
}


.sections-headers{
  text-transform: uppercase;
  font-size: var(--font-large);
  font-weight: 700;
  margin: 1rem 0;
}


.sections-headers-sub-text{
  font-size: var(--font-medium);
  margin: 1rem 3rem 1rem 0;
  text-align: center;
}

.learn-more-about{
  padding: .5rem 2.5rem;
  width: 5.4rem;
  transition: .2s;
  border: 1px solid var(--border-color);
}

.learn-more-about:hover{
  border-color: var(--ligh-yellow-color);
  color: var(--ligh-yellow-color);
}

.svg-inline--fa{
  color: var(--font-color-light);
}

.footer-card{
  flex: 1;
}

.footer-header{
  margin: 0 0 1rem;
  font-weight: 500;
}

.footer-contact{
  margin: 0 0 var(--space-thin);
}

.footer-newsletter-submit{
  background-color: #082746;
  color: var(--font-color-light);
  transition: .1s;
}

.footer-newsletter-submit:hover{
  background-color: var(--bg-color-dark);
  color: var(--font-color-light);
}


/* about classes */


/* about classes specific tags */

.our-mission-content-paragraph{
  line-height: 1.5rem;
}

.our-mission-list{
  position: relative;
  list-style: none;
  line-height: 1.8rem;
}

.our-mission-list::before{
  content: "🚀";
  position: absolute;
  left: -2.5em;

}

.what-we-offer{
  column-gap: var(--space-thin);
}

.what-we-offer-service{
  column-gap: 1rem;
}

.form{
  /* width: 25%; */
  background-color: var(--font-color-main);
}

.PagesForm-input{
  padding: 0.8rem 1.5rem;
  margin:  0.3rem 0;
  border: none;
}

.PagesForm-info{
  column-gap: .5rem;
}

.polygon-header{
  margin-bottom: 3rem;
  background-color: var(--ligh-yellow-color);
  padding: var(--space-thin) 1.5rem;
  clip-path: polygon(0 0, 100% 0%, 100% 75%, 50% 100%, 0 75%);
}

.polygon-header-text{
  font-weight: 500;
  color: var(--font-color-dark);
}

.polygon-header-subtext{
  font-weight: 800;
  font-size: var(--font-xlarge);
  color: var(--font-color-main);
}

.PagesForm-textarea{
  font-size: var(--font-medium);
  margin: .5rem 0;
  height: 8rem;
  border: none;
}

.PagesForm-submit{
  margin: .3rem 0 var(--space-thin) 0;
  text-transform: capitalize;
  font-weight: 800;
  letter-spacing: 1px;
  color: var(--font-color-main);
  background-color: var(--ligh-yellow-color);
  cursor: pointer;
  transition: .2s;
}

.PagesForm-submit:hover{
  background-color: #df7e00;
}


.contact-section-header{
  font-size: var(--font-xlarge);
  color: var(--highlight-red);
  font-weight: 700;
  line-height: 1.8rem;
  margin-bottom: var(--space-thin);
}


.contact-section-sub-header{
  font-size: var(--font-xxlarge);
  color: var(--font-color-main);
  font-weight: 700;
  line-height: 2.5rem;
}


.contact-section-p{
  margin-top: var(--space-thin);
  /* letter-spacing: 1px; */
}

.call-us-today{
  font-weight: 700;
  font-size: var(--font-large);
  color: var(--font-color-main);
}

.call-us-today-phone{
  color: var(--highlight-red);
  background-repeat: no-repeat;
}

.ContactForm{
 width: 35rem;
}


.ContactForm-input{
  padding: 0.8rem 1.5rem;
  margin:  0.3rem 0;
  border: none;
}

.man-contacts{
  height: 40rem;
}


.AllServices-services-section{
  display: grid;
  grid-template-columns: repeat(4, 23.48%);
  grid-row-gap: 1.5rem;
  grid-column-gap: 2%;
  margin-top: 3rem;
}

.map{width:85.8%;}



@media screen and (min-width: 1367px){
  :root{
    --space-thin: 2rem;
    --space-small: 4rem;
    --space-wide: 23rem;
  }

  .sections-headers-sub-text{
    margin: 1rem 20rem 1rem 0;
  }

  .map{width:61.34%;}
}


@media screen and (max-width: 1024px){

  html{
    margin: 0;
    padding: 0;
    width: 100vw;
    /* overflow-x: hidden; */
  }


  .grid-3-columns{
    grid-template-columns: auto auto;
  }
  .tablet-flex-column{flex-direction: column;}

  .half-display-width{
    width: 100%;
  }
  
}

/* @media screen and (min-width:541px){

} */



@media screen and (min-width:360px) and (max-width:540px){
  :root{
    --font-xxlarge: 2rem;
    --font-medium: .9rem;
  }
  html{
    margin: 0;
    padding: 0;
    /* width: 100vw; */
    /* overflow-x: hidden; */
  }

  .grid-3-columns{
    grid-template-columns: auto;
  }

  .half-display-width{
    width: 100%;
  }


  .hide-on-mobile{display: none;}
 
  .grid-3-columns{grid-template-columns: auto;}
  .mobile-flex-column{flex-direction: column;}
  
  .mobile-flex-align-start{align-items: flex-start;}

  .padding-2-2{padding: var(--space-thin) 2rem}
  .padding-0-2{padding: 0 2rem}
  .padding-2-0{padding: var(--space-thin) 2rem}

  .padding-2-4{padding: var(--space-thin) 2rem;}
  .padding-0-4{padding: 0 2rem;}

  .padding-2-6{padding: var(--space-thin) 2rem;}
  .padding-0-6{padding: 0 2rem;}

  .padding-right{
    padding-right: 0;
  }

  .side-top-bottom-padding{
    padding: 3rem 2rem;
  }

  .mobile-2rem-padding{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ThreeLoadingScreens{
    margin-left: 0;
  }

  header{
    background-color: transparent;
    padding: 0;
    position: -webkit-sticky;
    position: sticky;
    top: -5rem;
    z-index: 5;
  } 
  
  .hero{
    padding: 5rem 0;
    margin-top: -9rem;
    height: 30rem;
    /* height: 75vh; */
    position: relative;
    /* background-color: #4da9fa; */
  }


  .PagesForm-input{
    padding: 0.8rem 1.5rem;
    margin:  0.3rem 0;
    width: 50%;
    border: none;
  }

  .hide-on-desktop{display: block;}



  
  .fa-bars{
    font-size: 2rem;
    position: fixed;
    top: 1.5rem;
    right: .5rem;
    z-index: 5;
  }

  nav {
    align-items: flex-start;
  }

  .nav-div{
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   
  }

  nav > .nav-div> a{
    margin: 1rem .8rem;
  }




  .looking-for{
    font-size: 1.1rem;
  }


  .sections-content-paddings{
    padding: 2rem 0;
  }

  .ContactForm{
    width: 100%;
  }

  .polygon-header{
    text-align: center;
  }

  .polygon-header-subtext{
  font-size: 1.3rem;
  }

  .PagesForm-submit{
    width: 100%;
  }
   
  
}
















